import React from 'react'
import { styled } from '@mui/material'
import { array, bool, func, object, string } from 'prop-types'
import { SplitDeliveryText, SplitDeliveryTextWrapper } from '../cart-parts/styles'
import LargeHeader from '../cart-parts/large-header'
import StoreCartWrapper from './store-cart-wrapper'
import StoreCartParagraph from './store-cart-paragraph'
import StoreContactInfo from './store-contact-info'

const StoreCartDiv = styled('div')({
  backgroundColor: '#f7f7f7',
  display: 'grid',
  height: 'auto',
  paddingTop: 0,
  margin: 0,
  width: '100%',
})

const StoreCart = ({
  isMobile,
  onlineCartHasProducts,
  removeStoreCartProduct,
  setStoreCartIsLoading,
  splitDeliveryMessage,
  storeCartImages,
  storeCartItems,
  storeCartWarrantyItems,
  storeInfo,
  toggleStoreCartProductWarranty,
  unavailableItems,
}) => (
  <>
    <LargeHeader
      amount={storeCartItems?.length}
      isMobile={isMobile}
      setIsLoading={setStoreCartIsLoading}
      text="Store Cart"
      storeCartId={storeInfo?.storeCartId}
    />
    <StoreCartDiv>
      {isMobile && storeInfo && <StoreContactInfo storeInfo={storeInfo} hideHeader />}
      {splitDeliveryMessage && (
        <SplitDeliveryTextWrapper>
          <SplitDeliveryText>{splitDeliveryMessage}</SplitDeliveryText>
        </SplitDeliveryTextWrapper>
      )}
      {storeCartItems?.map((i, index) => {
        const key = `store_cart_item_${index}`
        const { showWarranty, warrantyPrice } = storeCartWarrantyItems?.find(w => w.sku === i.sku) ?? {}
        return (
          <StoreCartWrapper
            key={key}
            isMobile={isMobile}
            item={{
              ...i,
              index,
              isUnavailable: unavailableItems.includes(i.sku),
              parentImageUrl: storeCartImages ? storeCartImages[i.sku] ?? '' : '',
              showWarranty,
            }}
            removeStoreCartProduct={removeStoreCartProduct}
            storeInfo={storeInfo}
            toggleParentItemWarranty={() => toggleStoreCartProductWarranty(i.sku)}
            warrantyPrice={warrantyPrice}
          />
        )
      })}

      {(isMobile || onlineCartHasProducts) && (
        <StoreCartParagraph isMobile={isMobile} storeName={storeInfo?.storeName} />
      )}
    </StoreCartDiv>
  </>
)

StoreCart.propTypes = {
  isMobile: bool,
  onlineCartHasProducts: bool,
  removeStoreCartProduct: func,
  setStoreCartIsLoading: func,
  splitDeliveryMessage: string,
  storeCartImages: object,
  storeCartItems: array,
  storeCartWarrantyItems: array,
  storeInfo: object,
  toggleStoreCartProductWarranty: func,
  unavailableItems: array,
}

export default StoreCart
