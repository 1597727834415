import React, { useState } from 'react'
import { arrayOf, bool, func, object } from 'prop-types'
import { Stack } from '@mui/material'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Price from '../../../../@rtg2022/components/atoms/Price/Price'
import { ActiveAddonsDiv, InfoDiv, ProductListDiv, Quantity, Sku, Title, Img } from './styles'
import NoImageSvg from '../no-image-svg'
import StoreCartRemoveModal from '../store-cart-remove-modal'

const ProductListItems = ({ isMobile, isUnavailable, productListItems, toggleParentItemWarranty }) => {
  const [openModal, setOpenModal] = useState(false)

  const handleCancel = () => setOpenModal(false)
  const handleRemove = () => {
    setOpenModal(false)
    toggleParentItemWarranty()
  }

  // Use escCount to determine how many child items have ESC selected
  // Only render the StoreCartRemoveModal when multiple child items will be affected (ie, when escCount > 1)
  const escCount = productListItems.filter(i => i.warrantyEnabled && i.warrantyPrice)?.length ?? 0

  if (!isMobile) {
    return (
      <>
        <ProductListDiv>
          {productListItems.map(item => {
            const { imageUrl, key, quantity, showWarranty, sku, title, warrantyEnabled, warrantyPrice } = item
            return (
              <ActiveAddonsDiv key={key}>
                {imageUrl ? <Img src={imageUrl} /> : <NoImageSvg />}
                <InfoDiv>
                  <Title>
                    {title} {quantity > 1 ? <Quantity>{`(x${quantity})`}</Quantity> : null}
                  </Title>
                  <Sku>SKU: {sku}</Sku>
                  {showWarranty && warrantyPrice && (
                    <Stack direction="row" mt={2}>
                      <input
                        id={`storeWarrantyCheckBox_${sku}`}
                        type="checkbox"
                        name={`warranty-${sku}`}
                        checked={!!warrantyEnabled}
                        onChange={warrantyEnabled && escCount > 1 ? () => setOpenModal(true) : toggleParentItemWarranty}
                      />
                      <label htmlFor={`storeWarrantyCheckBox_${sku}`} style={{ fontSize: '14px', marginLeft: '12px' }}>
                        Furniture Protection Plan: <Price className="bold" price={warrantyPrice} />
                      </label>
                    </Stack>
                  )}
                  {showWarranty && !warrantyPrice && (
                    <span style={{ fontSize: '12px', fontStyle: 'italic', marginTop: '12px', whiteSpace: 'nowrap' }}>
                      Furniture Protection Plan not added. Contact store associate to add protection.
                    </span>
                  )}
                  {isUnavailable && (
                    <Stack mt={1} direction="row" alignItems="center" spacing={0.75}>
                      <WarningAmberIcon fontSize="small" htmlColor="#E11F21" />
                      <span style={{ color: '#E11F21', fontSize: 14, fontWeight: 500 }}>
                        Not available in your region
                      </span>
                    </Stack>
                  )}
                </InfoDiv>
              </ActiveAddonsDiv>
            )
          })}
        </ProductListDiv>
        {openModal && (
          <StoreCartRemoveModal
            headerText="Remove Protection Plan?"
            isOpen={openModal}
            isYesNo
            onCancel={handleCancel}
            onRemove={handleRemove}
          >
            <>
              This will remove the Protection Plan from the entire package. <br />
              If you would prefer to remove protection from a single item, <br />
              please contact your Store Associate. <br />
              <br />
              Would you like to remove the Protection Plan <br />
              from the entire package?
            </>
          </StoreCartRemoveModal>
        )}
      </>
    )
  }

  const hasChildren = productListItems.length > 1
  const firstItem = productListItems[0]

  return (
    <>
      <ProductListDiv>
        <ActiveAddonsDiv>
          {isUnavailable && (
            <Stack mt={1} direction="row" alignItems="flex-start" justifyContent="center" spacing={0.75}>
              <WarningAmberIcon fontSize="small" htmlColor="#E11F21" />
              <span style={{ color: '#E11F21', fontSize: 14, fontWeight: 500 }}>Not available in your region</span>
            </Stack>
          )}
          {firstItem.imageUrl ? <Img src={firstItem.imageUrl} /> : <NoImageSvg />}
          <InfoDiv>
            <Title>
              {firstItem.title} {firstItem.quantity > 1 ? <Quantity>{`(x${firstItem.quantity})`}</Quantity> : null}
            </Title>
            {firstItem.showWarranty && firstItem.warrantyPrice && (
              <Stack direction="row" mt={2}>
                <input
                  id={`storeWarrantyCheckBox_${firstItem.sku}`}
                  type="checkbox"
                  name={`warranty-${firstItem.sku}`}
                  checked={!!firstItem.warrantyEnabled}
                  onChange={
                    firstItem.warrantyEnabled && escCount > 1 ? () => setOpenModal(true) : toggleParentItemWarranty
                  }
                />
                <label
                  htmlFor={`storeWarrantyCheckBox_${firstItem.sku}`}
                  style={{ fontSize: '14px', marginLeft: '12px' }}
                >
                  Furniture Protection Plan: <Price className="bold" price={firstItem.warrantyPrice} />
                </label>
              </Stack>
            )}
            {firstItem.showWarranty && !firstItem.warrantyPrice && (
              <span style={{ fontSize: '12px', fontStyle: 'italic', margin: '12px' }}>
                Furniture Protection Plan not added.
                <br />
                Contact store associate to add protection.
              </span>
            )}
          </InfoDiv>
        </ActiveAddonsDiv>
        {hasChildren &&
          productListItems
            .slice(1)
            .map(({ imageUrl, key, quantity, showWarranty, sku, title, warrantyEnabled, warrantyPrice }) => (
              <ActiveAddonsDiv key={key}>
                {imageUrl ? <Img src={imageUrl} /> : <NoImageSvg />}
                <InfoDiv>
                  <Title>
                    {title} {quantity > 1 ? <Quantity>{`(x${quantity})`}</Quantity> : null}
                  </Title>
                  {showWarranty && warrantyPrice && (
                    <Stack direction="row" mt={2}>
                      <input
                        id={`storeWarrantyCheckBox_${sku}`}
                        type="checkbox"
                        name={`warranty-${sku}`}
                        checked={!!warrantyEnabled}
                        onChange={warrantyEnabled && escCount > 1 ? () => setOpenModal(true) : toggleParentItemWarranty}
                      />
                      <label htmlFor={`storeWarrantyCheckBox_${sku}`} style={{ fontSize: '14px', marginLeft: '12px' }}>
                        Furniture Protection Plan: <Price className="bold" price={warrantyPrice} />
                      </label>
                    </Stack>
                  )}
                  {showWarranty && !warrantyPrice && (
                    <span style={{ fontSize: '12px', fontStyle: 'italic', margin: '12px' }}>
                      Furniture Protection Plan not added.
                      <br />
                      Contact store associate to add protection.
                    </span>
                  )}
                </InfoDiv>
              </ActiveAddonsDiv>
            ))}
      </ProductListDiv>
      {openModal && (
        <StoreCartRemoveModal
          headerText="Remove Protection Plan?"
          isOpen={openModal}
          isYesNo
          onCancel={handleCancel}
          onRemove={handleRemove}
        >
          <>
            This will remove the Protection Plan from the entire package. If you would prefer to remove protection from
            a single item, please contact your Store Associate. <br />
            <br />
            Would you like to remove the Protection Plan from the entire package?
          </>
        </StoreCartRemoveModal>
      )}
    </>
  )
}

ProductListItems.propTypes = {
  isMobile: bool,
  isUnavailable: bool,
  productListItems: arrayOf(object),
  toggleParentItemWarranty: func,
}

export default ProductListItems
