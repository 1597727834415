import React, { useState } from 'react'
import { bool, func, number, object, string } from 'prop-types'
import { addDashesToNumber } from '@helpers/number-helper'
import { currencyFormatUS } from '@helpers/string-helper'
import CloseModal from '@components/shared/modals/close-modal'
import ProductPrice from '../../../../@rtg2022/components/atoms/Price/Price'
import { STORE_CART_UNREMOVABLE_SKUS } from '../constants'
import ProductListItems from '../product-list-items'
import StoreCartRemoveModal from '../store-cart-remove-modal'
import { CloseIcon, Count, Price, ProductList, ProductWrapper, Remove, Small } from './styles'

const PhoneNumber = ({ isMobile, phoneNumber }) => {
  if (isMobile) {
    return (
      <span style={{ fontWeight: 700 }}>
        <a href={`tel:${phoneNumber}`}>{addDashesToNumber(phoneNumber)}</a>
      </span>
    )
  }

  return <span style={{ fontWeight: 700 }}>{addDashesToNumber(phoneNumber)}</span>
}

PhoneNumber.propTypes = {
  isMobile: bool,
  phoneNumber: string,
}

const getProductListItems = (item, warrantyPrice) => {
  let productListItems
  if (item?.childItems?.length) {
    productListItems = item.childItems.map((childItem, i) => ({
      key: `${item.sku}_${childItem.sku}_${i}`,
      imageUrl: (childItem?.imageUrl || item?.parentImageUrl) ?? '',
      quantity: item.quantity * childItem.quantity,
      showWarranty: (item?.showWarranty ?? false) && !item?.isUnavailable,
      sku: childItem.sku,
      title: childItem.title ?? '',
      warrantyEnabled: childItem.warrantyEnabled ?? false,
      warrantyPrice: childItem?.warrantyPrice?.price,
    }))
  } else {
    productListItems = [
      {
        key: item.sku,
        imageUrl: item?.parentImageUrl ?? '',
        quantity: item.quantity,
        showWarranty: (item?.showWarranty ?? false) && !item?.isUnavailable,
        sku: item.sku,
        title: item.title ?? '',
        warrantyEnabled: item.warrantyEnabled ?? false,
        warrantyPrice: item?.warrantyPrice?.price || warrantyPrice,
      },
    ]
  }

  return productListItems
}

const StoreCartWrapper = ({
  isMobile,
  item,
  removeStoreCartProduct,
  storeInfo,
  toggleParentItemWarranty,
  warrantyPrice,
}) => {
  const [openModal, setOpenModal] = useState(false)

  const handleCancel = () => setOpenModal(false)
  const handleRemove = () => {
    setOpenModal(false)
    removeStoreCartProduct(item)
  }

  const cannotRemove = item.required || STORE_CART_UNREMOVABLE_SKUS.some(i => i.sku === item.sku)
  const productListItems = getProductListItems(item, warrantyPrice)

  return (
    <ProductWrapper className="product-wrapper">
      <Count>{item.index + 1}</Count>
      <ProductList>
        <ProductListItems
          isMobile={isMobile}
          isUnavailable={item?.isUnavailable ?? false}
          productListItems={productListItems}
          toggleParentItemWarranty={toggleParentItemWarranty}
        />
        <Price>
          <div>
            <ProductPrice price={currencyFormatUS(item?.unitPrice)} />
            <Small>{item?.childItems?.length > 1 ? ' Room package' : null}</Small>
          </div>
        </Price>
        <Remove type="button" onClick={() => setOpenModal(true)}>
          Remove
        </Remove>
        <CloseIcon>
          <CloseModal closeModal={() => setOpenModal(true)} />
        </CloseIcon>

        {openModal && (
          <StoreCartRemoveModal
            isOpen={openModal}
            onCancel={handleCancel}
            onRemove={cannotRemove ? null : handleRemove}
          >
            {cannotRemove ? (
              <>
                <p style={{ margin: '0 auto', maxWidth: isMobile ? null : 320 }}>
                  This product cannot be removed from your Cart online. Please contact the store associate listed below
                  to remove this product from your cart.
                </p>
                <br />
                Associate: <span style={{ fontWeight: 700 }}>{storeInfo?.salesPersonInfo ?? ''}</span>
                <br />
                Phone #:{' '}
                <PhoneNumber
                  isMobile={isMobile}
                  phoneNumber={storeInfo?.storePhoneNumber?.replaceAll(/[^0-9]+/g, '') ?? ''}
                />
              </>
            ) : (
              <>
                If you remove an item from your <br />
                store cart, it cannot be added back. <br />
                Would you like to remove the item?
              </>
            )}
          </StoreCartRemoveModal>
        )}
      </ProductList>
    </ProductWrapper>
  )
}

StoreCartWrapper.propTypes = {
  isMobile: bool,
  item: object,
  removeStoreCartProduct: func,
  storeInfo: object,
  toggleParentItemWarranty: func,
  warrantyPrice: number,
}

export default StoreCartWrapper
