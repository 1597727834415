import React from 'react'
import { bool } from 'prop-types'
import ApplyButton from '@shared/apply-button'
import { getFinanceMarketingMessageData } from '@helpers/finance'
import '@comp-sass/cart/cart-parts/credit-card-banner.sass'
import styled from 'styled-components'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { GROWTHBOOK_VARIANTS } from '@constants/growthbook-flags'

const ButtonWrapper = styled('div')`
  text-align: center;
  padding: 20px 20px 8px 20px;
  display: flex;
  flex-direction: column;
`

const Title = styled('h3')`
  color: #fff;
  background-color: rgb(8, 38, 59);
  text-align: center;
  font-weight: 600;
  margin-bottom: 16px;
`

const Description = styled('p')`
  p {
    color: #fff;
    background-color: rgb(8, 38, 59);
    text-align: justify;
    font-size: 12px;
  }
`

const LearnMoreLink = styled('button')`
  color: #fff;
  background-color: rgb(8, 38, 59);
  text-decoration: underline;
`

const OrText = styled('p')`
  color: #fff;
  background-color: rgb(8, 38, 59);
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledSyncronyButton = styled(ApplyButton)`
  background-color: #fff;
  color: rgb(8, 38, 59);
  padding: 10px 15px !important;
  font-weight: bold;
  border-radius: 6px;
  font-size: 1rem;
  text-transform: capitalize;
  align-self: center;
  white-space: nowrap;
  &:hover {
    background-color: #fff;
    color: rgb(8, 38, 59);
  }
`

const Wrapper = styled('div')`
  color: #fff;
  background-color: rgb(8, 38, 59) !important;
`

const CartCreditCardBanner = ({ isMobile = false }) => {
  const { siteFriendlyLabel, marketingMessage } = getFinanceMarketingMessageData()
  const sitecodes = {
    prequal: '&sitecode=rgbcrb3d1',
    apply: '&sitecode=rgawel1d2',
  }
  const showPreQual = useFeatureIsOn('cart-show-prequal')
  const isShowFinanceBannerMessageFeatureOn = useFeatureIsOn(
    GROWTHBOOK_VARIANTS.cart?.[isMobile ? 'mobile' : 'desktop']?.showFinanceBanner,
  )

  if (!isShowFinanceBannerMessageFeatureOn) return null
  return (
    <Wrapper className="credit-card-banner cell small-12">
      <Title>{siteFriendlyLabel}</Title>
      <Description
        dangerouslySetInnerHTML={{
          __html: marketingMessage,
        }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginTop: '8px' }}>
        <LearnMoreLink
          type="button"
          gtm-category="credit-card"
          gtm-action="learn more"
          gtm-label="cart"
          className="info-modal credit-learn-more synchrony-prequal-learn-more-cart"
        >
          Learn More
        </LearnMoreLink>
        <span>*</span>
      </div>

      <ButtonWrapper>
        {/* FD-1587: Ensure That Prequalification Button Always Displays on Cart Financing Banner */}
        {/* https://rtgjira.atlassian.net/browse/FD-1587 */}
        {showPreQual && (
          <>
            <StyledSyncronyButton
              asButton
              url={`${process.env.GATSBY_SYNCHRONY_URL}&preQual=Y${sitecodes.prequal}&modalapply=true`}
              category="credit-card"
              action="prequalify"
              label="cart"
              title="Rooms To Go Credit Options"
            >
              See if you Prequalify
            </StyledSyncronyButton>
            <OrText>OR</OrText>
          </>
        )}
        <StyledSyncronyButton
          asButton
          url={`${process.env.GATSBY_SYNCHRONY_URL}${sitecodes.apply}`}
          category="credit-card"
          action="apply-now"
          label="cart"
          title="Rooms To Go Credit Options"
        >
          Apply Now
        </StyledSyncronyButton>
      </ButtonWrapper>
    </Wrapper>
  )
}

CartCreditCardBanner.propTypes = {
  isMobile: bool,
}

export default CartCreditCardBanner
