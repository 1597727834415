import React, { useState } from 'react'
import { object, bool, func, array, any, number, string } from 'prop-types'
import { useSelector } from 'react-redux'
import { getCartTotal, getStoreCartTotal } from '@helpers/cart'
import { currencyFormatUS } from '@helpers/string-helper'
import { setOrderInfo } from '@helpers/checkout/global'
import { getSubtotalDisplayValue, getTotalDisplayValue } from '@helpers/order'
import { applePayEnabled, applePaySupported } from '@helpers/checkout/payment-section/apple-pay'
import StoreContactInfo from '@components/cart/store-cart/store-contact-info'
import CartCreditCardBanner from '@components/cart/cart-parts/CartCreditCardBanner'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { getPromoMessage } from '@helpers/finance'
import { GROWTHBOOK_VARIANTS } from '@constants/growthbook-flags'
import styled from 'styled-components'
import LearnMorePromoFee from '../../../@rtg2022/components/molecules/ProductTile/LearnMorePromoFee'
import RTGLink from '../../shared/link'
import '../../../assets/css/components/checkout/checkout-sticky/checkout-sticky.sass'
import arrowUp from '../../../assets/images/icons8-chevron-up-24.png'
import arrowDown from '../../../assets/images/icons8-chevron-down-24.png'
import PaypalButton from '../checkout-parts/payment-section/paypal'
import ApplePayButton from '../checkout-parts/payment-section/apple-pay'
import SalesContact from '../../cart/cart-parts/sales-contact'
import EquickBanner from '../../cart/equickBanner'
import PriceAtom from '../../../@rtg2022/components/atoms/Price/Price'
import PromotionsModal from './promotions-modal'

const AppleBtnSpan = styled('span')`
  height: 42px;
  margin-bottom: 0.5em;
  & #apple-pay-button {
    margin-top: 0 !important;
  }
`

const CheckoutSticky = ({
  cart,
  discount,
  eQuickProps = {},
  financeAmount,
  financeNumOfMonths,
  isMobile = false,
  onlyButtons,
  order,
  productsAvailable,
  removeUnavailableItems,
  rightSticky,
  showPayPal,
  storeCartIsLoading,
  storeCartLineItems,
  featureVariant,
}) => {
  const [expanded, setExpanded] = useState(false)
  const [promoExpanded, setPromoExpanded] = useState(false)
  const [enableApplePay, setEnableApplePay] = useState(applePaySupported() ? applePayEnabled() : false)
  const checkoutStepsCompleted = useSelector(state => state?.checkout?.checkoutStepsCompleted) ?? {}

  const isShowFinanceCalculatorMessageFeatureOn = useFeatureIsOn(
    GROWTHBOOK_VARIANTS.cart?.[isMobile ? 'mobile' : 'desktop']?.showFinanceCalculator,
  )

  const isCheckout = false

  let cartTotal = getCartTotal(cart)
  if (storeCartLineItems) {
    cartTotal += getStoreCartTotal(storeCartLineItems)
  }
  const closeModal = () => {
    setPromoExpanded(false)
  }
  const orderDiscount = discount
  const orderDiscountLabel = order.couponType === 'crypto' ? `BitPay Coupon (${order.couponAmount}%)` : 'Offers Applied'

  const thisTax = checkoutStepsCompleted.delivery && order.tax > -1 ? order.tax : -2

  /* Totals */
  const subtotal = getSubtotalDisplayValue(order, cart, storeCartLineItems)
  const total = getTotalDisplayValue(order, isCheckout, checkoutStepsCompleted, cart, storeCartLineItems)

  const dtIdPrefix = isMobile ? 'mobile-cart-' : 'desktop-cart-'

  const { isPrequalified = false, ...eqProps } = eQuickProps

  const promoMessageHTML = getPromoMessage(total, cart, false, false)

  return (
    <>
      {(!isMobile || expanded) && !onlyButtons && rightSticky && order?.storeInfo && (
        <div className="checkout-sticky grid-x cell small-12 desktop" style={{ marginBottom: 15 }}>
          <StoreContactInfo storeInfo={order?.storeInfo} />
        </div>
      )}
      <div className="checkout-sticky cell small-12 large-4 grid-x">
        {isMobile && (
          <button
            type="button"
            aria-expanded={isMobile && expanded}
            aria-controls="your-order-summary"
            className="order-detail-expander"
            onClick={() => setExpanded(current => !current)}
          >
            {!expanded ? (
              <img className="arrowUp" src={arrowUp} alt="expand order details" />
            ) : (
              <img className="arrowDown" src={arrowDown} alt="collapse order details" />
            )}
          </button>
        )}
        {(!isMobile || expanded) && !onlyButtons && (
          <div className="cell small-12 overflow-auto mobile-max-44vh">
            <div id="your-order-summary" className="cell small-12">
              <div className="checkout-header">
                <h3>YOUR ORDER</h3>
              </div>

              <div className="checkout-info top grid-x">
                <div className="small-7 mb8">
                  <p className="left">Subtotal</p>
                </div>
                <div className="small-5">
                  <p className="right" data-testid={`${dtIdPrefix}summary-SubtotalPrice`}>
                    <PriceAtom price={currencyFormatUS(subtotal, true)} />
                  </p>
                </div>
                {orderDiscount > 0 && (
                  <>
                    <div className="small-7 mb8">
                      <button
                        type="button"
                        className="left promos-button"
                        value="See applied promotions"
                        aria-label="See applied promotions"
                        onClick={() => setPromoExpanded(true)}
                      >
                        {orderDiscountLabel}
                      </button>
                    </div>
                    <div className="small-5">
                      <p className="right subtract" data-testid={`${dtIdPrefix}summary-OfferPrice`}>
                        -<PriceAtom price={currencyFormatUS(orderDiscount, true)} />
                      </p>
                    </div>
                  </>
                )}
                <div className="small-9 mb8">
                  <p className="left at-checkout">Delivery Fee & Tax applied at Checkout</p>
                </div>
                <div className="small-3 mb8">
                  <p className="right period" data-testid={`${dtIdPrefix}summary-ShippingPrice`}>
                    --
                  </p>
                </div>

                {!!order?.paymentInfo?.length && (
                  <>
                    <div className="small-7 mb8">
                      <p className="left bold">Total</p>
                    </div>
                    <div className="small-5">
                      <p className="right bold">
                        <PriceAtom price={currencyFormatUS(order.total, true)} />
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div id="your-order-total" className="checkout-info bottom grid-x">
              <div className="small-7">
                <p className={`total-txt ${orderDiscount > 0 ? 'total-discount' : ''}`}>{`${
                  order && order.paymentInfo && order.paymentInfo.length ? 'Remaining Balance' : 'Total'
                } ${orderDiscount > 0 ? ' After Discounts' : ''}`}</p>
              </div>
              <div className="small-5">
                {thisTax === -1 && <p className="right">...calculating</p>}
                {thisTax !== -1 && (
                  <p className="total-price bolder" data-testid={`${dtIdPrefix}summary-TotalPrice`}>
                    <PriceAtom price={currencyFormatUS(total, true)} />
                  </p>
                )}
              </div>
              {isShowFinanceCalculatorMessageFeatureOn && financeAmount >= 5 && (
                <div className="small-12">
                  <p className="right red-text total-txt-small">
                    ${financeAmount}/mo. with {financeNumOfMonths} months financing*
                    {promoMessageHTML && <LearnMorePromoFee price={total} promoMessageHTML={promoMessageHTML} />}
                  </p>
                </div>
              )}
            </div>
            {isMobile && (
              <>
                <div className="cell small-12 checkout-info bottom grid-x">
                  <div
                    className="cell button-container small-12 medium-8 large-12 grid-x"
                    style={enableApplePay ? { alignItems: 'baseline' } : null}
                  >
                    <span
                      className={`${
                        enableApplePay ? 'small-6' : 'small-12'
                      } large-6 paypal-btn-container mobile cell paypal`}
                    >
                      {!storeCartIsLoading && <PaypalButton isExpress total={total} order={order} show={showPayPal} />}
                    </span>
                    {enableApplePay && (
                      <div className="cell small-6 large-6 mobile apple-pay" style={{ height: 42 }}>
                        <ApplePayButton isExpress type="plain" /> {/* mobile && summary drawer is expanded */}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
        {((isMobile && !expanded) || onlyButtons) && (
          <div className="cell small-12 checkout-info bottom grid-x">
            <div className="small-12 grid-x padding-lr">
              <div className="small-7">
                <p className={`total-txt ${orderDiscount > 0 ? 'total-discount' : ''}`}>{`${
                  order && order.paymentInfo && order.paymentInfo.length ? 'Remaining Balance' : 'Total'
                } ${orderDiscount > 0 ? ' After Discounts' : ''}`}</p>
              </div>
              <div className="small-5">
                {thisTax === -1 && <p className="right">...calculating</p>}
                {thisTax !== -1 && (
                  <p className="total-price bolder" data-testid={`${dtIdPrefix}TotalPrice`}>
                    <PriceAtom price={currencyFormatUS(total, true)} />
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        {!isCheckout && (
          <div className="cell small-12 checkout-info bottom grid-x">
            {productsAvailable && (
              <div className="cell button-container small-12 medium-8 large-12 grid-x">
                {isMobile && !expanded && (
                  <>
                    <span
                      className={`${enableApplePay ? 'small-6' : 'small-12'} cell paypal`}
                      style={enableApplePay ? null : { marginBottom: '8px' }}
                    >
                      {!storeCartIsLoading && (
                        <PaypalButton
                          isExpress
                          total={total}
                          order={order}
                          rightSticky={rightSticky}
                          show={showPayPal}
                          setOrderInfo={() => setOrderInfo('PayPal', 'selectedPaymentType')}
                        />
                      )}
                    </span>
                    {enableApplePay && (
                      <AppleBtnSpan className="cell small-6 apple-pay">
                        <ApplePayButton isExpress type="plain" /> {/* mobile && summary drawer is collapsed */}
                      </AppleBtnSpan>
                    )}
                  </>
                )}
                <span className="cell small-12 checkout-btn-container">
                  <RTGLink
                    data={{
                      slug: '/checkout',
                      category: 'cart',
                      action: 'checkout',
                      label: 'checkout',
                    }}
                    disabled={storeCartIsLoading}
                  >
                    <span className={storeCartIsLoading ? 'action-btn checkout disabled' : 'action-btn checkout'}>
                      Checkout now
                    </span>
                  </RTGLink>
                </span>

                {(!isMobile || (isMobile && onlyButtons)) && rightSticky && (
                  <>
                    <div
                      className="cell small-12 paypal-btn-container"
                      style={enableApplePay ? { marginBottom: '4px' } : null}
                    >
                      {!storeCartIsLoading && (
                        <PaypalButton
                          isExpress
                          total={total}
                          order={order}
                          rightSticky={rightSticky}
                          show={showPayPal}
                          setOrderInfo={() => setOrderInfo('PayPal', 'selectedPaymentType')}
                        />
                      )}
                    </div>
                    {enableApplePay && (
                      <div className="cell small-12" style={{ height: 42 }}>
                        <ApplePayButton isExpress type="plain" /> {/* desktop order summary */}
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
            {!productsAvailable && (
              <div className="cell unavailable-container small-12 medium-8 large-12 grid-x">
                <div className="cell small-12 checkout-btn-container">
                  <button type="button" className="action-btn checkout disabled" value="Checkout Now" disabled>
                    Checkout now
                  </button>
                </div>
                <p className="disabled-text">
                  We apologize, but some items are out of stock or unavailable in your region. Please review your cart
                  and make changes as necessary.
                </p>
                <button
                  type="button"
                  className="remove-unavailable"
                  value="Remove all unavailable items"
                  onClick={removeUnavailableItems}
                >
                  Remove all unavailable items
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <PromotionsModal modalOpen={promoExpanded} closeModal={closeModal} promotions={order?.promotions} />
      {!isMobile && !onlyButtons && rightSticky && (
        <>
          {isPrequalified && !storeCartIsLoading && <EquickBanner isSidebar {...eqProps} />}
          {!isPrequalified && <CartCreditCardBanner isMobile={isMobile} />}
          <SalesContact />
        </>
      )}
    </>
  )
}

CheckoutSticky.propTypes = {
  cart: object,
  discount: any,
  eQuickProps: object,
  financeAmount: number,
  financeNumOfMonths: number,
  isMobile: bool,
  onlyButtons: bool,
  productsAvailable: bool,
  removeUnavailableItems: func,
  rightSticky: bool,
  showPayPal: bool,
  storeCartIsLoading: bool,
  storeCartLineItems: array,
  order: object,
  featureVariant: string,
}

export default CheckoutSticky
